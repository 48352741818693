import functions from '../functions.js';
import { list } from './list';

$(() => {
    listAccommodation.init();
});

const listAccommodation = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        personsInObject: 0,
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        pool: '',
        parking: '',
        pets: '',
        internet: '',
        dateFrom: '',
        dateTo: '',
        dates: null,
        destination: '',
        countryId: '',
        objectGroupId: 1,
        serviceId: null,
        objectTypeId: '',
        guests: '',
        tagCategoryId: null,
        tagCategoryIds: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: null,
        tagCategoryIds: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null, 
        pool: null, 
        parking: null, 
        pets: null, 
        internet: null
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'persons', 'personsInObject', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form.search-accommodation');
        this.form = this.$form[0];

        if(! this.form) {
            return;
        }

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];  

        this.$listItems = $('.list-items');
        this.isHome = this.$form.attr('data-ishome') || ! this.$listItems.length;

        this.getParams();


        if (this.params.dateFrom && this.params.dateTo) {
            this.params.dates = this.params.dateFrom + ' - ' + this.params.dateTo;
        }

        functions.setFormData(this.form, this.params);
        if(this.filter) {
            functions.setFormData(this.filter, this.params);
        }

        if($('[name=guests]').val()) {
            this.params.guests = $('[name=guests]').val();
        }

        this.find();

        $('.input-child').on('change', e => {
            e.preventDefault();
            e.stopPropagation();
        });

        if( ! functions.isMobile() && ! this.isHome) {
            this.$form.on('change', e => {
                e.preventDefault();
                this.getFormData(this.$form);
                this.getDataForUrl()
                functions.setUrlData(this.params, true, true);
            });
        }


        this.$form.on('submit', e => {
            e.preventDefault();
            const $target = $(e.target);
            this.getFormData($target);
            this.getDataForUrl()

            if(this.isHome) {
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true);
            }

        });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });

        $('.btn-clear-filters').on('click', e => {
            e.preventDefault();

            this.getParams();
            this.params._page = 1;

            functions.setFormData(this.form, this.defaults);
            if(this.filter) {
                functions.setFormData(this.filter, this.defaults);
            }
            this.getDataForUrl()
            functions.setUrlData(this.params, true);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            functions.scrollToElement(this.$listItems, 100);
            this.find();
        });

        $('[name=dates]').on('change', e => {
            const $target = $(e.target);
            if ($target.val().indexOf(' - ') !== -1) {
                $(".datepicker-actions").removeClass('d-none');
            } else {
                $(".datepicker-actions").addClass('d-none');
            }
        });

    },
    getDataForUrl() {
        if (this.params.guests == '0,0') {
            this.params.guests = null;
        }
        this.params = functions.getDataForUrl(this.params);
    },
    getFormData($form) {
        this.params = functions.getFormData($form);
    },
    getParams() {
        this.params = list.getParams(this.params, this.filterParams);
        this.splitCalendarDates(this.params.dates);
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    find() {
        if (this.params.dateFrom && ! this.params.dateTo) return;

        list.query = $.extend({}, this.defaults, this.params);
        list.params = this.params;

        list.find().then((response) => {
            list.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    }
};