import functions from "./functions.js";

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        insurance: null,
        status: null,

        init() {

            booking.$form = $(".booking-form");
            booking.$calculation = $(".table-calculation");
            booking.params = {};
            booking.$payment = $(".payment-details");

            // BOOKING PAYMENT TYPE CHECKBOX
            if($('[name="paymentType"]')[0]) {
                checkPaymentType();
                $('[name="paymentType"]').on('change', e => {
                    checkPaymentType();
                });

                function checkPaymentType() {
                    $('[name="paymentType"]').each((index, item) => {
                        const $item = $(item);
                        const id = $item.attr('id');
                        $('[for=' + id + ']').find('[id='  + id + ']').prop('checked', $item.is(':checked'));
                    });
                }
            }

            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            $('.booking-page').on('change', 'input[type="checkbox"]', function (e) {
                let $row = $(e.currentTarget).closest('.calc-item');
                let $quantity = $row.find('[name=quantity]');
                let value = +$quantity.val();
                let checked = $(e.currentTarget).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(e.currentTarget));
            });

            $('.booking-page').on('change', 'select.quantity', function (e) {
                let $row = $(e.currentTarget).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(e.currentTarget).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(e.currentTarget));
            });

            let termsUrl = $('[data-termsurl]').attr('data-termsurl') || null;
            let privacyUrl = $('[data-privacyurl]').attr('data-privacyurl') || null;

            if(termsUrl) {
                let html = '';
                $.get(termsUrl).done(response => {
                    html = $('<div>' + response + '</div>').find('.container.p-4.bg-white.rounded.shadow');
                    $('.booking-form').find('.term-text').html(html);
                });
            }

            if(privacyUrl) {
                let html = '';
                $.get(privacyUrl).done(response => {
                    html = $('<div>' + response + '</div>').find('.container.p-4.bg-white.rounded.shadow');
                    $('.booking-form').find('.privacy-text').html(html);
                });
            }

            booking.total();

            $('.booking-page').on('submit', '.booking-form', e => {

                if ($('#agree').hasClass('disabled'))
                {
                    e.preventDefault();
                    return
                }
                $('button[type="submit"]').attr('disabled', 'disabled');

                booking.submit();
            });

        },
        submit() {
            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                }
            });

            booking.params.paymentType = booking.$payment.find("[name='paymentType']:checked").val() ? booking.$payment.find("[name='paymentType']:checked").val() : 'bankTransfer';
            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            if (booking.params.paymentType == "creditCardTotalWithInstallments") {
                booking.params.paymentType = 'creditCard';
                booking.params.paymentPlan = true;
                booking.params.paymentAmount = 'total';
            }

            booking.params.partnerId = booking.$form.attr('data-partnerid');

            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

        },
        calculate($elem) {
            let $row = $elem.closest('.calc-item');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            let id = $checkbox.attr('id');

            let $quantity = $row.find('select.quantity:visible');
            let quantity = null;

            if ($quantity.length) {
                quantity = +$quantity.val();
            }

            $.each(booking.calc, function (key, calc) {
                if (calc.id == id) {

                    if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }

                    booking.calc[key].inCalc = checked;
                    booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(booking.calc[key].total, 2, ',', '.'));
                }
            });


            booking.total();
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek', 'Once']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },

        total() {

            let totalAmount = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    totalAmount += booking.itemTotal(calc);
                }
            });

            $('.cart-total-amount').html(functions.formatMoney(totalAmount, 2, ',', '.'));

            const advancePaymentPercent = $('[data-advancePaymentPercent]').attr('data-advancepaymentpercent');
            const advance = totalAmount * (advancePaymentPercent / 100);
            const rest = totalAmount - advance;

            booking.$payment.find('.payment-type-wrapper').each((index, item) => {
                const $wrapper = $(item);
                const commission = +$wrapper.attr('data-commission');
                const $advance = $wrapper.find('.advance');
                const $rest = $wrapper.find('.rest');
                const $totalWithInstallments = $wrapper.find('.totalWithInstallments');

                if (commission) {
                    $advance && $advance.html(functions.formatMoney(advance + ((advance * commission) / 100), 2, ',', '.'));
                    $rest && $rest.html(functions.formatMoney(rest + ((rest * commission) / 100), 2, ',', '.'));
                    $totalWithInstallments && $totalWithInstallments.html(functions.formatMoney(totalAmount + ((totalAmount * commission) / 100), 2, ',', '.'));
                } else {
                    $advance && $advance.html(functions.formatMoney(advance, 2, ',', '.'));
                    $rest && $rest.html(functions.formatMoney(rest, 2, ',', '.'));
                    $totalWithInstallments && $totalWithInstallments.html(functions.formatMoney(totalAmount, 2, ',', '.'));
                }
            });
        }
    }

    booking.init();
});