import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';
import { DetailsCart } from './details-cart.js';

$(() => {
    detailsAccommodation.init();
})

const detailsAccommodation = {
    $details: null,
    $form: null,
    form: null,
    $cart: null,
    cart: null,
    availability: {},
    params: {
        idList: null,
        objectId: null,
        unitId: null,
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null,
        objectGroupId: null,
        pricelistId: null,
        calcErrorMessages: null,
    },
    init() {

        this.$details = $('.page-type-details-accommodation');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] && this.params.objectGroupId != 1) {
            return;
        }

        detailsAccommodation.$form = $('.availability-form');
        this.params.objectId = this.$details.attr('data-objectid');

        this.getParams();

        if (this.params.dateFrom && this.params.dateTo) this.params.dates = this.params.dateFrom + ' - ' + this.params.dateTo;

        functions.setFormData(detailsAccommodation.$form[0], this.params);
        this.getCalculation();

        detailsAccommodation.$form.on('submit', e => {
            e.preventDefault();

            let params = functions.getFormData($(e.currentTarget));
            functions.setUrlData(params, true);
        });

        $('[name=guests], [name="serviceCode"], [name="optionals"], [name="pricelistId"]').on('change', e => {
            let params = functions.getFormData(this.$form);
            functions.setUrlData(params, true, true);
        });

        $('[name=dates]').on('change', e => {
            let params = functions.getFormData(this.$form);
            if (params.dates.indexOf(' - ') !== -1) {
                functions.setUrlData(params, true, true);
            }
        });

        $(window).on('hashchange', e => {
            this.getParams();
            if (detailsAccommodation.params.dateFrom && detailsAccommodation.params.dateTo && detailsAccommodation.params.guests != '0,0') {
                $(".spinner-border").removeClass('d-none')
            }
            this.getCalculation();
        });
        this.inquiryInit();
        this.initCart();

    },

    initCart() {

        this.$cart = $('.details-cart');
        this.cart = new DetailsCart(this.params);
        this.cart.removeCartItem = (index) => {
            if (index) {
                let cartItem = this.cart.cartItems.filter((item, i) => {
                    return i == index;
                });

                cartItem = JSON.parse(cartItem[0]);

                let quantity = this.getAvailableQuantity(cartItem.unitId, cartItem.dateFrom, cartItem.dateTo) + 1;
                this.updateAvailableQuantity(cartItem.unitId, cartItem.dateFrom, cartItem.dateTo, quantity);

                if (quantity && cartItem.dateFrom == detailsAccommodation.params.dateFrom && cartItem.dateTo == detailsAccommodation.params.dateTo) {
                    let $unit = $('.unit[data-unitid="'+cartItem.unitId+'"]');
                    $unit.find('.add-to-cart-custom').removeClass('d-none');
                    $unit.find('.btn-soldOut').addClass('d-none');
                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').removeClass('d-none');
                }
            }

            DetailsCart.prototype.removeCartItem.call(this.cart, index);
        }

        $('.btn-booking').on('click', e => {
            e.preventDefault();
            if ( !this.cart.cartItems.length ) {
                $('#booking')[0].scrollIntoView();
            } else {
                let cartData = [];

                this.cart.cartItems.forEach(item => {
                    item = JSON.parse(item);
                    cartData.push({
                        'unitId': item.unitId,
                        'dateFrom': item.dateFrom,
                        'dateTo': item.dateTo,
                        'guests': item.guests,
                    });
                });

                let url = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData) + '&addAllOptionals=true';
                
                if(this.cart.cartItems.length) {
                    location = url;
                } else {
                    $('#detailsCartModal').modal('show');
                    setTimeout(() => {
                        $('#detailsCartModal').modal('hide');
                    }, 2500);
                }
            }
        });

        $('.add-to-cart-custom').on('click', e => {

            e.preventDefault();
            const $target = $(e.currentTarget);
            let unitId = $target.attr('data-unitid');

            let quantity = this.getAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo);

            quantity = quantity - 1;
            this.updateAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo, quantity);

            let params = {};
            params.unitId = $target.attr('data-unitid');
            params.unitTitle = $target.attr('data-unittitle');
            params.unitCartTotal = +$target.attr('data-unitcarttotal');
            params.unitCartTotalOld = +$target.attr('data-unitcarttotalold');
            params.dateFrom = this.params.dateFrom;
            params.dateTo = this.params.dateTo;
            params.guests = this.params.guests;
            this.cart.currencySymbol = '€';

            this.cart.addToCart(params);
            this.cart.displayCartItems(this.cartItems);
            this.cart.removeCartItem(null);

            $('#detailsCartModal').modal('show');
            setTimeout(() => {
                $('#detailsCartModal').modal('hide');
            }, 2500);

            if ( ! quantity)
            {
                let $unit = $('.unit[data-unitid="'+unitId+'"]');
                $unit.find('.add-to-cart-custom').addClass('d-none');
                $unit.find('.unit-price-total-label').addClass('d-none');
                $unit.find('.unit-price-total').addClass('d-none');
                $unit.find('.unit-price-old').addClass('d-none');
                $unit.find('.btn-soldOut').removeClass('d-none');
            }
        });
    },

    getAvailableQuantity(unitId, dateFrom, dateTo) {
        return detailsAccommodation.availability[unitId + "_" + dateFrom + "_" + dateTo];
    },

    updateAvailableQuantity(unitId, dateFrom, dateTo, quantity) {
        detailsAccommodation.availability[unitId + "_" + dateFrom + "_" + dateTo] = quantity;
    },

    printDates() {
        $('.date-from').text(functions.formatDate(this.params.dateFrom));
        $('.date-to').text(functions.formatDate(this.params.dateTo));
    },
    getParams() {

        this.params = details.getParams(this.params);
        detailsAccommodation.splitCalendarDates(this.params.dates);
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getCalculation() {

        details.getCalculation(this.params)

            .done(response => {
                this.calculationDone(response);
                $(".spinner-border").addClass('d-none')
            }).fail(error => {
                //console.log(error);
            });
    },
    loader($target = null) {
        if ($target) {
            $target.addClass('active');
        } else {
            $('.unit-spinner-block').removeClass('d-none');
            $('.unit-spinner').addClass('active');
        }

        setTimeout(() => {
            if ($target) {
                $target.removeClass('active');
            } else {
                $('.unit-spinner-block').addClass('d-none');
                $('.unit-spinner').removeClass('active');
            }
        }, 800);
    },
    isBookable(data) {
        return (data.booking.status == 'Reservation' && data.isAvailable && data.calc.status == 'Active') || data.booking.status == 'Request' ;
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                let quantity = unitData ? +unitData.quantity : 0;
                let available = this.getAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo);

                if ( ! available && available !== 0) {
                    this.updateAvailableQuantity(unitId, detailsAccommodation.params.dateFrom, detailsAccommodation.params.dateTo, quantity);
                }
                else {
                    quantity = available;
                }

                $unit.find('.unit-price-label').addClass('d-none');

                if(this.isBookable(unitData) && quantity) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);

                    if(+priceOld.toFixed(2) > +total.toFixed(2)) {
                        $unit.find('.unit-price-old').removeClass('d-none');
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                        $unit.find('.add-to-cart-custom').attr('data-unitcarttotalold', priceOld);
                    } else {
                        $unit.find('.unit-price-old').html('');
                        $unit.find('.unit-price-old').addClass('d-none');
                    }

                    $unit.find('.add-to-cart-custom').removeClass('d-none');
                    $unit.find('.btn-soldOut').addClass('d-none');
                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));
                    $unit.find('.add-to-cart-custom').attr('data-unitcarttotal', total);

                    this.printDates();

                    if ($unit.find('.unit-spinner.active').length) {
                        $unit.find('.unit-price-total-label').addClass('d-none');
                        $unit.find('.unit-price-total').addClass('d-none');
                        $unit.find('.unit-price-old').addClass('d-none');
                    }
                } else {
                    $unit.find('.add-to-cart-custom').addClass('d-none');
                    $unit.find('.unit-price-total-label').addClass('d-none');
                    $unit.find('.unit-price-total').addClass('d-none');
                    $unit.find('.unit-price-old').addClass('d-none');
                    $unit.find('.btn-soldOut').removeClass('d-none');
                }
            });
        }
    },
    inquiryInit() {
        let now = functions.cleanDate(new Date);
        let $datesI = null;
        $('.datepicker-range-inquiry').each((i, elem) => {
            const $datepicker = $(elem);
            const minDate = $datepicker.attr('data-min');
            const maxDate = $datepicker.attr('data-max');
            const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
            const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
            const duration = Number($datepicker.attr('data-duration'));
            const position = $datepicker.attr('data-position') || 'auto';
            const minStayText = $datepicker.attr('data-minstay');
            $datesI = $datepicker.flatpickr({
                mode: "range",
                showMonths: functions.isMobile() ? 1 : 2,
                altInput: true,
                wrap: true,
                altFormat: "j.m.Y",
                dateFormat: "Y-m-d",
                defaultDate: defaultDate,
                minDate: minDate && minDate >= now ? minDate : now,
                maxDate: maxDate ? maxDate : null,
                disableMobile: true,
                prevArrow: '<i class="la la-arrow-left la-fw la-2x pr-2"></i>',
                nextArrow: '<i class="la la-arrow-right la-fw la-2x pl-2"></i>',
                locale: {
                    rangeSeparator: ' - '
                },
                position: position,
                minStayText: minStayText ? minStayText : '',
                onDayCreate(dObj, dStr, fp, dayElem) {

                    let minStay = 1;
                    let to = new Date(maxDate);
                    let weekday = new Date(dayElem.dateObj).getDay();
                    weekday = '' + (weekday ? weekday : 7);
                    to.setHours(0, 0, 0, 0);
                    let onlyCheckInSelected = fp.selectedDates[0] && !fp.selectedDates[1];
                    // let diff = globals.dateDiff(dayElem.dateObj, fp.selectedDates[0]);
                    let from = dayElem.dateObj;
                    let too = fp.selectedDates[0];
                    // let f = Date.UTC(from.getFullYear(), from.getMonth()+1, from.getDate());
                    // let t = Date.UTC(too.getFullYear(), too.getMonth()+1, too.getDate());

                    let diff = Math.round((from - too) / (1000 * 60 * 60 * 24));

                    if (onlyCheckInSelected) {
                        if (Math.abs(diff) < minStay) {
                            dayElem.className += ' flatpickr-disabled ';
                            if (diff) {
                                if (minStayText) {
                                    $(dayElem).tooltip({
                                        title: minStayText.replace('{$min_stay}', minStay) || ''
                                    });
                                }
                            }
                        }
                    }

                    if (dayElem.dateObj > to || (arrival.length && $.inArray(weekday, arrival) == -1)) {
                        dayElem.classList.add('unselectable');
                    }
                },
            });
            // to style clear toggler
            $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
        });

        $('form#inquiry').on('submit', e => {
            e.preventDefault();


            $('.contact-btn').addClass('d-none');
            $('.contact-spinner').removeClass('d-none');
            $('.contact-spinner').addClass('d-flex');

            let params = {};
            let allParams = $(e.currentTarget).serializeArray();
            $.each(allParams, function (i, field) {
                params[field.name] = field.value;
            });

            params['dates'] = params.datesi || '';

            if (!params.dates) {
                $datesI.open();
                $('.contact-btn').removeClass('d-none');
                $('.contact-spinner').addClass('d-none');
                $('.contact-spinner').removeClass('d-flex');
                return;
            }

            functions.setFormData($('form#inquiry'), params);
            params['language'] = functions.getLang();

            params['requestId'] = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);
            grecaptcha.ready(function () {
                grecaptcha.execute('6LeymoQqAAAAAM0WeaIJqF3JTVd1aUKCXO0I6vqS', { action: 'contact' }).then(function (token) {
                    params['gRecaptchaToken'] = token;
                    params = functions.getDataForUrl(params);
                    $.post('/services/inquiry/', params).then(response => {
                        response.status ? $('.request-success').html(response.data).removeClass('d-none') 
                            : $('.request-error').html(response.data).removeClass('d-none');

                        $('.contact-spinner').addClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');
                        setTimeout(() => {
                            $('.contact-btn').removeClass('d-none');
                            
                            $(".request-error, .request-success").addClass('d-none');
                        }, 7000);

                        if (response.status == true) {
                            e.currentTarget.reset();
                        }
                    });
                });
            });
        });
    },
};
